.topbar {
  padding: 2rem 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  transition: all ease-in-out 0.5s, background-color 0s;
  backdrop-filter: blur(2rem);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 0;

  @media screen and (max-width: 639px) {
    padding: 1rem 0;
  }

  &.--header {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
  }

  &.--main {
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  &__wrapper {}

  &__logo {
    height: 9rem;
    width: 9rem;
    transition: all 0.5s;

    @media screen and (max-width: 991px) {
      height: 6rem;
      width: 6rem;
    }

    @media screen and (max-width: 639px) {
      height: 3rem;
      width: 3rem;
    }
  }

  &__logo-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__inner {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__icon-link {
    margin-left: 2rem;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    color: var(--color__black-2);
    transition: 0.24s;

    &:hover {
      color: var(--color__pink-1)
    }

    &:focus {
      outline: transparent;
    }

    &:focus-visible {
      outline: 1px solid var(--color__pink-1);
    }

    &:active {
      color: var(--color__purple);
    }

    &+& {
      margin-left: 2rem;
    }
  }

  &__icon-svg {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
    object-position: center;
  }

  &__lang-link {
    padding: 0.25em;
    line-height: 1;
    margin-left: 2rem;
  }

  &__burger {
    display: none;
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;
    position: relative;
    margin-left: auto;
    z-index: 21;

    @media screen and (max-width: 991px) {
      display: block;
    }
  }

  &__burger-row {
    position: absolute;
    width: 2rem;
    height: 1px;
    border-radius: 0.25rem;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--color__black-1);
    transition: all ease-in-out 0.25s;

    &:nth-child(1) {
      top: 1rem;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 1rem;
    }
  }

  &__burger:active &__burger-row {
    background-color: var(--color__pink-1);
  }

  &__burger.--is-active &__burger-row {
    &:nth-child(1) {
      transform: translate(-50%, -50%) rotate(45deg);
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: translate(-50%, -50%) rotate(-45deg);
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
    }
  }

  &__menu-wrapper {
    margin-top: 2rem;
  }

  &__menu-mobile {
    flex-direction: column;
    align-items: flex-end;
  }
}