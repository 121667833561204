.cases {
  margin-top: 6rem;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2.5rem;

    @media screen and (max-width: 1023px) {
      grid-template-columns: 100%;
    }
  }

  &__item {
    margin: 0 auto;

    &:nth-child(1) {
      grid-column: span 2;

      @media screen and (max-width: 1023px) {
        grid-column: 1;
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      max-width: 31rem;

      @media screen and (max-width: 1023px) {
        max-width: unset;
      }
    }

    &:nth-child(3) {
      grid-column: span 3;

      @media screen and (max-width: 1023px) {
        grid-column: 1;

      }
    }

    &:nth-child(4) {}

    &:nth-child(5) {
      grid-column: span 2;

      @media screen and (max-width: 1023px) {
        grid-column: 1;
      }
    }
  }
}