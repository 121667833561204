@include font-face("Noto Sans", "NotoSans-Black", 900);
@include font-face("Noto Sans", "NotoSans-BlackItalic", 900, "italic");

@include font-face("Noto Sans", "NotoSans-ExtraBold", 800);
@include font-face("Noto Sans", "NotoSans-ExtraBoldItalic", 800, "italic");

@include font-face("Noto Sans", "NotoSans-Bold", 700);
@include font-face("Noto Sans", "NotoSans-BoldItalic", 700, "italic");

@include font-face("Noto Sans", "NotoSans-SemiBold", 600);
@include font-face("Noto Sans", "NotoSans-SemiBoldItalic", 600, "italic");

@include font-face("Noto Sans", "NotoSans-Medium", 500);
@include font-face("Noto Sans", "NotoSans-MediumItalic", 500, "italic");

@include font-face("Noto Sans", "NotoSans-Regular", 400);
@include font-face("Noto Sans", "NotoSans-Italic", 400, "italic");

@include font-face("Noto Sans", "NotoSans-Light", 300);
@include font-face("Noto Sans", "NotoSans-LightItalic", 300, "italic");

@include font-face("Noto Sans", "NotoSans-ExtraLight", 200);
@include font-face("Noto Sans", "NotoSans-ExtraLightItalic", 200, "italic");

@include font-face("Noto Sans", "NotoSans-Thin", 100);
@include font-face("Noto Sans", "NotoSans-ThinItalic", 100, "italic");



@include font-face("Nunito", "Nunito-Black", 900);
@include font-face("Nunito", "Nunito-BlackItalic", 900, "italic");

@include font-face("Nunito", "Nunito-ExtraBold", 800);
@include font-face("Nunito", "Nunito-ExtraBoldItalic", 800, "italic");

@include font-face("Nunito", "Nunito-Bold", 700);
@include font-face("Nunito", "Nunito-BoldItalic", 700, "italic");

@include font-face("Nunito", "Nunito-SemiBold", 600);
@include font-face("Nunito", "Nunito-SemiBoldItalic", 600, "italic");

@include font-face("Nunito", "Nunito-Medium", 500);
@include font-face("Nunito", "Nunito-MediumItalic", 500, "italic");

@include font-face("Nunito", "Nunito-Regular", 400);
@include font-face("Nunito", "Nunito-Italic", 400, "italic");

@include font-face("Nunito", "Nunito-Light", 300);
@include font-face("Nunito", "Nunito-LightItalic", 300, "italic");

@include font-face("Nunito", "Nunito-ExtraLight", 200);
@include font-face("Nunito", "Nunito-ExtraLightItalic", 200, "italic");