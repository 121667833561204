.case {
  position: relative;
  max-width: 55.25rem;
  z-index: 2;

  @media screen and (max-width: 1023px) {
    max-width: unset;
    width: 100%;
  }

  &__inner {
    position: sticky;
    top: 6rem;
  }

  &__header {
    opacity: 0;
    transition: all ease-in-out 0.75s;
  }

  &__logo {
    width: 8.25rem;
    height: 5.75rem;

    @media screen and (max-width: 639px) {
      height: 10rem;
      width: 10rem;
    }
  }

  &__logo-image {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
    object-position: left top;
  }

  &__description {
    margin-left: 2rem;

    @media screen and (max-width: 639px) {
      margin-left: 1rem;
      font-size: 0.75rem;
      flex: 0 0 75%;
    }
  }

  &__title {
    color: var(--color__black-2);
  }

  &__text {
    color: var(--color__black-1);
    margin-top: 1.5rem;
  }

  &__body {
    opacity: 0.5;
    margin-top: 2rem;
    transition: all ease-in-out 0.75s;
  }

  &__pic {
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.5rem;
    box-shadow: 0 0 2rem var(--color__shadow-4);
    padding: 0;
  }

  &__image {
    display: block;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__tags {
    opacity: 0;
    margin-top: 2rem;
    transition: all ease-in-out 0.75s;
    flex-wrap: wrap;
  }

  &__tag {
    white-space: nowrap;

    @media screen and (max-width: 639px) {
      font-size: 0.75rem;
      margin: 0.5rem;
    }

    &+& {
      margin-left: 1.5rem;

      @media screen and (max-width: 639px) {
        margin: 0.5rem;
      }
    }
  }

  &:hover &__header {
    opacity: 1;
  }

  &:hover &__body {
    opacity: 1;
  }

  &:hover &__tags {
    opacity: 1;
  }

  &.--small {
    .case__description {
      margin-left: 1rem;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
      }
    }

    .case__title {
      font-size: 1rem;
    }

    .case__text {
      font-size: 0.75rem;
    }
  }
}