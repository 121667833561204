.button {
  @include no-btn;
  font-family: 'Nunito';
  font-weight: 700;
  color: var(--color__back);
  padding: 0.5em 2em;
  background-color: var(--color__pink-2);
  box-shadow: 0.25em 0.25em 1em var(--color__shadow-3);
  border-radius: 0.25em;
  transition: 0.24s;

  &:hover {
    color: var(--color__back);
    background-color: var(--color__pink-1);
    box-shadow: 0 0.25em 1em var(--color__shadow-1),
      -16px 16px 48px var(--color__shadow-3);
  }

  &:focus {
    color: var(--color__back);
    outline: transparent;
  }

  &:focus-visible {
    color: var(--color__back);
    outline: 2px solid var(--color__pink-4);
    background-color: var(--color__pink-3);
    text-decoration: underline;
  }

  &:active {
    color: var(--color__back);
    background-color: var(--color__pink-2);
    box-shadow: 0.25em 0.25em 1em var(--color__shadow-3);
  }
}