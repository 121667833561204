.question {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0.5rem 0.5rem 1.5rem var(--color__shadow-5);
  border-radius: 0.5rem;

  &__title {
    @include no-btn;
    text-align: left;
    width: 100%;
    padding: 1.25em 3.5em 1.25em 1.5em;
    position: relative;
    color: var(--color__black-1);
    transition: all ease-in-out 0.25s;

    @media screen and (max-width: 639px) {
      font-size: 0.75rem;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      background-color: var(--color__black-2);
      height: 1px;
      width: 1em;
      top: 2em;
      right: 2em;
      transition: all ease-in-out 0.25s;
    }

    &::after {
      transform: rotate(90deg);
    }

    &:hover::after,
    &:hover::before {
      background-color: var(--color__purple);
    }

    &:hover {
      color: var(--color__purple);
    }
  }

  &__title-text {
    color: inherit;

  }

  &__content-wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease-in-out .5s;
  }

  &__content-inner {
    padding: 0 1.5em 1.25em;

    @media screen and (max-width: 639px) {
      font-size: 0.75rem;
    }
  }

  &__content-text {
    &+& {
      margin-top: 1em;
    }
  }

  &.--is-opening {
    .question__title::after {
      opacity: 0;
      transform: rotate(0);
    }
  }

  &.--is-open {
    .question__title::after {
      opacity: 0;
      transform: rotate(0);
    }

    .question__content-wrapper {
      height: auto;
    }
  }
}