.header {
  min-height: 100vh;

  // padding: 4rem 0;
  padding-bottom: 1rem;

  @media screen and (max-width: 1023px) {
    padding-bottom: 1rem;
  }

  &__inner {
    @media screen and (max-width: 1023px) {
      flex-direction: column-reverse;
    }
  }

  &__content {
    flex: 0 1 45rem;
    position: relative;
    z-index: 2;
    user-select: none;

    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
      width: 100%;
      margin-top: 3rem;
      text-align: center;
    }
  }

  &__title {
    min-height: 2em;

    @media screen and (max-width: 767px) {
      min-height: 3em;
    }
  }

  &__text {
    margin-top: 0.5rem;
    font-size: 1.5em;

    @media screen and (max-width: 639px) {
      font-size: 1rem;
    }
  }

  &__link {
    margin-top: 3.5rem;
  }

  &__pic,
  &__video-wrapper {
    flex: 0 1 45rem;

    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
      width: 100%;
      max-width: 30rem;
      margin: 0 auto;
    }
  }

  &__video {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: right center;
  }
}